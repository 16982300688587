import { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	GenerateCampaignAIContent,
	GenerateCampaignTestContent,
	GetCampaign,
	GetCampaignDefaultSettings,
	GetCampaignKeywords,
	UpdateCampaign,
	UpdateCampaignDefaultSettings,
	UpdateCampaignKeywords,
	UpdateCampaignStatus
} from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignKeywordsScreen.css';
import { toast } from 'react-toastify';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import { replaceMergedFieldsInDefaultSettings } from '../../../utils/Common';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import DndCard from '../../../components/dragAndDrop/DragAndDropCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const CampaignKeywordsScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isLoadingDialog, setIsLoadingDialog ] = useState(false);
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ keywords, setKeywords ] = useState([]);
	const [ keywordsForList, setKeywordsForList ] = useState([]);
	const [ formData, setFormData ] = useState({});
	const [ defaultSettingsData, setDefaultSettingsData ] = useState({});
	const [ showDialog, setShowDialog ] = useState(false);
	const [ showDefaultSettingsDialog, setShowDefaultSettingsDialog ] = useState(false);
	const [ defaultSettingsUpdated, setDefaultSettingsUpdated ] = useState(false);
	const [ isGeneratingContentTest, setIsGeneratingContentTest ] = useState(false);
	const [ isContentTest, setIsContentTest ] = useState(false);
	const [ contentTestResponse, setContentTestResponse ] = useState('');
	const [ editIndex, setEditIndex ] = useState();
	const contentTestResponseRef = useRef();
	const { campaignId } = useParams();
	const navigate = useNavigate();
	const [ appendNearMe, setAppendNearMe ] = useState(false);
	const [ useNearInsteadOfIn, setUseNearInsteadOfIn ] = useState(false);
	const [ isGettingKeywordItems, setIsGettingKeywordItems ] = useState(false);
	const [ keywordItemsStr, setKeywordItemsStr ] = useState();

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCampaignKeywords();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (keywords && keywords.length) {
				let tempKeywordsForList = keywords.filter((x) => x.state !== 'removed');
				setKeywordsForList(tempKeywordsForList);
				setItemsIndex(tempKeywordsForList);
			}
		},
		[ keywords ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;

			setAppendNearMe(response.addNearMeInReports);
			setUseNearInsteadOfIn(response.useNearInsteadOfIn);
			setCampaign(response);
		}
	};

	const getCampaignKeywords = async () => {
		setIsLoading(true);

		const response = await GetCampaignKeywords(campaignId);

		if (response) {
			let tempKeywords = response;

			tempKeywords = tempKeywords.sort(function(a, b) {
				return a.orderIndex - b.orderIndex;
			});

			for (let i = 0; i < tempKeywords.length; i++) {
				tempKeywords[i].index = i;
				tempKeywords[i].state = '';
			}

			setKeywords(tempKeywords);
		}

		setIsLoading(false);
	};

	const getKeywordItems = async (e, keyword) => {
		if (e) e.preventDefault();

		if (
			!defaultSettingsData.keywordItemsContentPrompt ||
			defaultSettingsData.keywordItemsContentPrompt.trim() === ''
		) {
			toast.error('Please set the keyword items command first.');
			return;
		}

		if (!keyword || keyword.trim() === '') {
			toast.error('Please set the keyword first.');
			return;
		}

		setIsGettingKeywordItems(true);

		let tempInstructions = defaultSettingsData.keywordItemsContentPrompt;

		if (keyword) {
			tempInstructions = tempInstructions.replaceAll('{INDUSTRY}', keyword);
		}

		let data = {
			instructions : tempInstructions
		};

		const response = await GenerateCampaignAIContent(campaignId, data);

		if (response.success && response.data) {
			//remove numbers from response items
			let itemsArray = response.data.split('<br>');

			if (itemsArray && itemsArray.length > 0) {
				for (let i = 0; i < itemsArray.length; i++) {
					itemsArray[i] = itemsArray[i].replace(i + 1 + '. ', ''); //remove ordinals
					itemsArray[i] = itemsArray[i].replace(/^-+/, ''); //remove hyphen
					itemsArray[i] = itemsArray[i].trim();
				}
			}

			setKeywordItemsStr(itemsArray.join('\n'));
		}

		setIsGettingKeywordItems(false);
	};

	useEffect(
		() => {
			if (keywordItemsStr) {
				setFormData({
					...formData,
					keywordItems : keywordItemsStr
				});
			}
		},
		[ keywordItemsStr ]
	);

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleSaveItem = (e) => {
		e.preventDefault();

		if (!formData.keyword || formData.keyword.trim() === '') {
			toast.error('Keyword is required!');
			return;
		}

		if (!formData.keywordItems || formData.keywordItems.trim() === '') {
			toast.error('Keyword items field is required!');
			return;
		}

		let tempKeywords = keywords.map((x) => x);

		if (editIndex !== undefined) {
			//edit
			let currentKeyword = tempKeywords.find((x) => x.index === editIndex);

			if (currentKeyword) {
				currentKeyword.keyword = formData.keyword;
				currentKeyword.headlinePrompt = formData.headlinePrompt;
				currentKeyword.contentPrompt = formData.contentPrompt;
				currentKeyword.keywordItems = formData.keywordItems.replaceAll('\n', '|');
				currentKeyword.state = currentKeyword.state === 'added' ? 'added' : 'updated';
			}
		} else {
			//new
			tempKeywords.push({
				index          : tempKeywords.length,
				orderIndex     : tempKeywords.length + 1,
				id             : null,
				keyword        : formData.keyword,
				headlinePrompt : formData.headlinePrompt,
				contentPrompt  : formData.contentPrompt,
				keywordItems   : formData.keywordItems.replaceAll('\n', '|'),
				state          : 'added'
			});
		}

		setItemsIndex(tempKeywords);

		setKeywords(tempKeywords);
		setFormData({});
		setShowDialog(false);
	};

	const handleSubmit = async (redirect = false) => {
		//e.preventDefault();

		let tempKeywords = keywords.map((x) => x);

		if (!tempKeywords || tempKeywords.length === 0) {
			toast.error('Please add keyword first');
			return;
		}

		setIsSubmitting(true);

		let data = {
			id       : campaignId,
			keywords : []
		};

		for (let i = 0; i < tempKeywords.length; i++) {
			data.keywords.push({
				id             : tempKeywords[i].id,
				keyword        : tempKeywords[i].keyword,
				headlinePrompt : tempKeywords[i].headlinePrompt,
				contentPrompt  : tempKeywords[i].contentPrompt,
				keywordItems   : tempKeywords[i].keywordItems,
				orderIndex     : tempKeywords[i].orderIndex,
				state          : tempKeywords[i].state
			});
		}

		const response = await UpdateCampaignKeywords(campaignId, data);

		if (response.success) {
			saveCampaign();

			if (redirect) {
				navigate(`/setup/${campaignId}/services`);
			} else {
				toast.success('Settings saved.');
				getCampaignKeywords();
			}
		}

		setIsSubmitting(false);
	};

	const handleToggleDialog = async (index) => {
		setShowDialog(!showDialog);

		if (!showDialog) {
			var defaultSettings = await GetCampaignDefaultSettings();
			setDefaultSettingsData(defaultSettings.data);

			if (index !== undefined) {
				//edit
				setEditIndex(index);
				let currentKeyword = keywords.find((x) => x.index === index);

				if (currentKeyword) {
					if (currentKeyword.keywordItems) {
						currentKeyword.keywordItems = currentKeyword.keywordItems.replaceAll('|', '\n');
					}

					setFormData(currentKeyword);
				}
			} else {
				//new
				setEditIndex();

				let tempFormData = { ...formData };

				if (tempFormData.keywordItems) {
					tempFormData.keywordItems = tempFormData.keywordItems.replaceAll('|', '\n');
				}

				// //get prompt values from campaign
				// if (campaign.defaultHeadlinePrompt) {
				// 	tempFormData.headlinePrompt = campaign.defaultHeadlinePrompt;
				// }
				// if (campaign.defaultContentPrompt) {
				// 	tempFormData.contentPrompt = campaign.defaultContentPrompt;
				// }

				// if (!tempFormData.headlinePrompt || !tempFormData.contentPrompt) {
				if (defaultSettings && defaultSettings.data && defaultSettings.data) {
					// var settings = replaceMergedFieldsInDefaultSettings(defaultSettings.data, campaign);

					if (!tempFormData.headlinePrompt) {
						// tempFormData.headlinePrompt = settings.keywordsHeadlinePrompt;
						tempFormData.headlinePrompt = defaultSettings.data.regenerateHeadlinePrompt;
					}
					if (!tempFormData.contentPrompt) {
						// tempFormData.contentPrompt = settings.keywordsContentPrompt;
						tempFormData.contentPrompt = defaultSettings.data.regenerateContentPrompt;
					}
				}
				// }

				setFormData(tempFormData);
			}
		} else {
			setFormData({});
		}
	};

	useEffect(
		() => {
			if (showDialog && defaultSettingsData) {
				if (isLoadingDialog) {
					setIsLoadingDialog(false);
				}
			}
		},
		[ showDialog, defaultSettingsData ]
	);

	const handleToggleDefaultSettingsDialog = async () => {
		setShowDefaultSettingsDialog(!showDefaultSettingsDialog);

		if (!showDefaultSettingsDialog) {
			let response = await GetCampaignDefaultSettings();

			if (response && response.data) {
				setDefaultSettingsData(response.data);
			}
		} else {
			if (defaultSettingsUpdated && editIndex === undefined) {
				var defaultSettings = await GetCampaignDefaultSettings();

				if (defaultSettings && defaultSettings.data && defaultSettings.data) {
					// var settings = replaceMergedFieldsInDefaultSettings(defaultSettings.data, campaign);

					// setFormData({
					// 	...formData,
					// 	headlinePrompt : settings.keywordsHeadlinePrompt,
					// 	contentPrompt  : settings.keywordsContentPrompt
					// });

					setFormData({
						...formData,
						headlinePrompt : defaultSettings.data.keywordsHeadlinePrompt,
						contentPrompt  : defaultSettings.data.keywordsContentPrompt
					});
				}
			}
		}
	};

	const handleDefaultSettingsChange = async (e) => {
		setDefaultSettingsData({
			...defaultSettingsData,
			[e.target.name]: e.target.value
		});
	};

	const handleSaveDefaultSettings = async () => {
		let response = await UpdateCampaignDefaultSettings(defaultSettingsData);

		if (response) {
			setDefaultSettingsUpdated(true);
			handleToggleDefaultSettingsDialog();
			toast.success('Default settings updated successfully.');
		} else {
			toast.error(response.message);
		}
	};

	const generateContentTestResponse = async (isContent = true) => {
		setIsGeneratingContentTest(true);

		let data = {
			id            : campaignId,
			keyword       : formData.keyword,
			contentPrompt : isContent ? formData.contentPrompt : formData.headlinePrompt,
			keywordItems  : formData.keywordItems.replaceAll('\n', '|')
		};

		const response = await GenerateCampaignTestContent(campaignId, data);

		if (response.success && response.response) {
			setContentTestResponse(response.response);
		} else {
			toast.error(response.message);
			console.error(response);
		}

		setIsGeneratingContentTest(false);
	};

	const toggleTestContentResponse = async (isContent = true) => {
		if (!isContentTest) {
			if (!formData.keyword || formData.keyword.trim() === '') {
				toast.error('Please set keyword first');
				return;
			}

			if (isContent) {
				if (!formData.contentPrompt || formData.contentPrompt.trim() === '') {
					toast.error('Please set content prompt first');
					return;
				}
			} else {
				if (!formData.headlinePrompt || formData.headlinePrompt.trim() === '') {
					toast.error('Please set headline prompt first');
					return;
				}
			}

			setIsContentTest(!isContentTest);

			await generateContentTestResponse(isContent);
		} else {
			setIsContentTest(!isContentTest);
		}
	};

	const handleSetPromptAsDefault = async (isContent = true) => {
		//e.preventDefault();

		let tempCampaign = campaign;

		if (isContent) {
			tempCampaign.defaultContentPrompt = formData.contentPrompt;
		} else {
			tempCampaign.defaultHeadlinePrompt = formData.headlinePrompt;
		}

		const response = await UpdateCampaign(campaignId, tempCampaign);

		if (response.success) {
			setCampaign(tempCampaign);
			toast.success('Value set as default successfully');
		}
	};

	const handleItemMove = useCallback(
		(dragIndex, hoverIndex) => {
			const dragItem = keywords[dragIndex];
			const hoverItem = keywords[hoverIndex];

			// Swap places of dragItem and hoverItem in the keywords array
			setKeywords((keywords) => {
				const updatedKeywords = [ ...keywords ];
				updatedKeywords[dragIndex] = hoverItem;
				updatedKeywords[hoverIndex] = dragItem;

				//update index
				updatedKeywords[hoverIndex].index = hoverIndex;
				updatedKeywords[dragIndex].index = dragIndex;

				//update state
				updatedKeywords[hoverIndex].state = 'updated';
				updatedKeywords[dragIndex].state = 'updated';

				setItemsIndex(updatedKeywords);

				return updatedKeywords;
			});
		},
		[ keywords ]
	);

	const handleItemRemove = useCallback(
		(index) => {
			if (window.confirm('Are you sure that you want to remove this keyword?')) {
				setKeywords((keywords) => {
					const item = keywords[index];
					const updatedKeywords = [ ...keywords ];

					if (item.state === 'added') {
						delete updatedKeywords[index];
					} else {
						updatedKeywords[index].state = 'removed';
					}

					setItemsIndex(updatedKeywords);

					return updatedKeywords;
				});
			}
		},
		[ keywords ]
	);

	const setItemsIndex = (keywordsArray) => {
		if (keywordsArray && keywordsArray.length) {
			for (let i = 0; i < keywordsArray.length; i++) {
				keywordsArray[i].index = i;
				keywordsArray[i].orderIndex = i + 1;
			}
		}
	};

	const saveCampaign = async () => {
		let tempCampaignData = campaign;
		tempCampaignData.addNearMeInReports = appendNearMe;
		tempCampaignData.useNearInsteadOfIn = useNearInsteadOfIn;

		const response = await UpdateCampaign(campaignId, tempCampaignData);

		if (!response.success) {
			console.log(response.message);
		}
	};

	return (
		<Fragment>
			<div className="container-fluid campaign-main-container">
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>

				<CampaignTabs />

				<Fragment>
					<div className="campaign-form-container">
						{isLoading && <Spinner />}

						<div className="row">
							<div className="col-12">
								<button
									className="btn btn-primary mb-3"
									type="button"
									onClick={() => handleToggleDialog()}
								>
									Add Keyword
								</button>
							</div>
						</div>

						{!isLoading &&
						keywordsForList &&
						keywordsForList.length > 0 && (
							<Fragment>
								<div className="row mb-1">
									<div className="col-12">
										<table className="table border keywords-table">
											<thead>
												<tr>
													<th scope="col" className="index">
														<span>Index</span>
													</th>
													<th scope="col" className="keyword">
														<span>Keyword</span>
													</th>
													<th scope="col" className="headline-prompt">
														<span>Headline Prompt</span>
													</th>
													<th scope="col" className="content-prompt">
														<span>Content Prompt</span>
													</th>
													<th />
												</tr>
											</thead>
											<tbody>
												<DndProvider backend={HTML5Backend}>
													{keywordsForList.map((keyword, index) => {
														return (
															keyword.state !== null &&
															keyword.state !== 'removed' && (
																<DndCard
																	index={index}
																	id={keyword.id ? keyword.id : ''}
																	isTableRow={true}
																	key={index}
																	moveCard={handleItemMove}
																	text={
																		<Fragment>
																			<td className="keyword">
																				{keyword.index + 1 || 0}
																			</td>
																			<td className="keyword">
																				{keyword.keyword}
																			</td>
																			<td
																				className="headline-prompt text-truncate"
																				title={keyword.headlinePrompt}
																			>
																				{keyword.headlinePrompt}
																			</td>
																			<td
																				className="content-prompt text-truncate"
																				title={keyword.contentPrompt}
																			>
																				{keyword.contentPrompt}
																			</td>
																			<td className="keyword-action text-end">
																				<button
																					href="#"
																					className="btn btn-link p-0"
																					onClick={() =>
																						handleToggleDialog(index)}
																					title="Edit Keyword"
																				>
																					<i className="fa-solid fa-pen" />
																				</button>
																				<button
																					href="#"
																					className="btn btn-link p-0 ms-2"
																					onClick={() =>
																						handleItemRemove(index)}
																					title="Remove Keyword"
																				>
																					<i className="fa-solid fa-trash-can" />
																				</button>
																			</td>
																		</Fragment>
																	}
																/>
															)
														);
													})}
												</DndProvider>
											</tbody>
										</table>
									</div>
								</div>

								<div className="row mb-1">
									<div className="col-12">
										<div className="form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id="addNearMeInReports"
												name="addNearMeInReports"
												onChange={(e) => setAppendNearMe(e.target.checked)}
												checked={appendNearMe}
											/>
											<label htmlFor="addNearMeInReports" className="form-check-label w-auto">
												Append "near me" when searching
											</label>
										</div>
									</div>
								</div>

								<div className="row mb-1">
									<div className="col-12">
										<div className="form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id="useNearInsteadOfIn"
												name="useNearInsteadOfIn"
												onChange={(e) => setUseNearInsteadOfIn(e.target.checked)}
												checked={useNearInsteadOfIn}
											/>
											<label htmlFor="useNearInsteadOfIn" className="form-check-label w-auto">
												Use "near" instead of "in"
											</label>
										</div>
									</div>
								</div>
							</Fragment>
						)}

						{showDialog && (
							<ModalDialog position="right-sidebar">
								<ModalDialogContent align="start">
									{isContentTest ? (
										<Fragment>
											{isGeneratingContentTest ? (
												<Spinner />
											) : (
												<Fragment>
													<div className="row">
														<div className="col-12">
															<h5 className="mb-3">{`Result for keyword "${formData.keyword}"`}</h5>
														</div>
													</div>
													<div className="row">
														<div
															//ref={contentTestResponseRef}
															className="col-12"
															dangerouslySetInnerHTML={{ __html: contentTestResponse }}
														/>
													</div>
												</Fragment>
											)}
										</Fragment>
									) : (
										<Fragment>
											{isLoadingDialog ? (
												<Spinner />
											) : (
												<form className="row">
													<div className="col-12 mb-3">
														<label htmlFor="keyword" className="form-label">
															Keyword:
														</label>
														<input
															type="text"
															className="form-control"
															name="keyword"
															value={formData.keyword || ''}
															onChange={handleChange}
															disabled={isSubmitting || isGettingKeywordItems}
														/>
														{formData.keyword && (
															<div className="form-controls-additional">
																<a
																	className="btn btn-link p-0"
																	href={`https://www.google.com/search?q=${formData.keyword}`}
																	target="_blank"
																>
																	Test
																</a>
															</div>
														)}
													</div>
													<div className="col-12 mb-3">
														<label htmlFor="headlinePrompt" className="form-label">
															Headline Prompt:
														</label>
														<textarea
															className="form-control"
															name="headlinePrompt"
															value={formData.headlinePrompt || ''}
															onChange={handleChange}
															rows={5}
															disabled={isSubmitting || isGettingKeywordItems}
														/>
														<div className="form-controls-additional">
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => toggleTestContentResponse(false)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Test
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={() => handleSetPromptAsDefault(false)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Set As Default
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={handleToggleDefaultSettingsDialog}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Settings
															</button>
														</div>
													</div>
													<div className="col-12 mb-3">
														<label htmlFor="contentPrompt" className="form-label">
															Content Prompt:
														</label>
														<textarea
															className="form-control"
															name="contentPrompt"
															value={formData.contentPrompt || ''}
															onChange={handleChange}
															rows={5}
															disabled={isSubmitting || isGettingKeywordItems}
														/>
														<div className="form-controls-additional">
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={toggleTestContentResponse}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Test
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={handleSetPromptAsDefault}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Set As Default
															</button>
															<span className="link-separator mx-1">|</span>
															<button
																type="button"
																className="btn btn-link p-0"
																onClick={handleToggleDefaultSettingsDialog}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																Settings
															</button>
														</div>
													</div>
													<div className="col-12">
														<label htmlFor="keywordItems" className="form-label">
															Keyword Items:
														</label>
														<textarea
															className="form-control"
															name="keywordItems"
															value={formData.keywordItems || ''}
															onChange={handleChange}
															rows={6}
															required
															disabled={isSubmitting || isGettingKeywordItems}
															wrap="off"
														/>
														<div>
															<button
																className="btn btn-link ps-0"
																type="button"
																onClick={(e) => getKeywordItems(e, formData.keyword)}
																disabled={isSubmitting || isGettingKeywordItems}
															>
																{isGettingKeywordItems ? (
																	<Fragment>
																		<span className="spinner-border m-0 me-2" />
																		<span>Getting Keywords...</span>
																	</Fragment>
																) : (
																	<span>Get Keywords</span>
																)}
															</button>
														</div>
													</div>
												</form>
											)}
										</Fragment>
									)}
								</ModalDialogContent>
								<ModalDialogButtons>
									{isContentTest ? (
										<button
											className="btn btn-outline-primary ms-2"
											type="button"
											onClick={toggleTestContentResponse}
										>
											Close
										</button>
									) : (
										<Fragment>
											<button className="btn btn-primary" type="button" onClick={handleSaveItem}>
												{isSubmitting ? (
													<Fragment>
														<span className="spinner-border m-0 me-2" />
														<span>Saving</span>
													</Fragment>
												) : (
													<span>Save</span>
												)}
											</button>
											<button
												className="btn btn-outline-primary ms-2"
												type="button"
												onClick={handleToggleDialog}
												disabled={isSubmitting || isGettingKeywordItems}
											>
												Cancel
											</button>
										</Fragment>
									)}
								</ModalDialogButtons>
							</ModalDialog>
						)}

						{showDefaultSettingsDialog && (
							<ModalDialog position="right-sidebar">
								<ModalDialogContent align="start">
									<h4 className="mb-3">Default Settings</h4>
									<form className="row">
										<div className="col-12 mb-3">
											<label htmlFor="headlineInstructionsAI" className="form-label">
												Headline Instructions:
											</label>
											<input
												type="text"
												className="form-control"
												name="headlineInstructionsAI"
												value={defaultSettingsData.headlineInstructionsAI || ''}
												onChange={handleDefaultSettingsChange}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="descriptionInstructionsAI" className="form-label">
												Description Instructions:
											</label>
											<textarea
												className="form-control"
												name="descriptionInstructionsAI"
												value={defaultSettingsData.descriptionInstructionsAI || ''}
												onChange={handleDefaultSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="keywordsHeadlinePrompt" className="form-label">
												Keywords Headline Prompt:
											</label>
											<textarea
												className="form-control"
												name="keywordsHeadlinePrompt"
												value={defaultSettingsData.keywordsHeadlinePrompt || ''}
												onChange={handleDefaultSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12">
											<label htmlFor="keywordsContentPrompt" className="form-label">
												Keywords Content Prompt:
											</label>
											<textarea
												className="form-control"
												name="keywordsContentPrompt"
												value={defaultSettingsData.keywordsContentPrompt || ''}
												onChange={handleDefaultSettingsChange}
												rows={3}
											/>
										</div>
									</form>
								</ModalDialogContent>
								<ModalDialogButtons>
									<button className="btn btn-primary" onClick={handleSaveDefaultSettings}>
										<span>Save</span>
									</button>
									<button
										className="btn btn-outline-primary"
										onClick={handleToggleDefaultSettingsDialog}
									>
										<span>Cancel</span>
									</button>
								</ModalDialogButtons>
							</ModalDialog>
						)}
					</div>

					<CampaignButtonsContainer
						campaign={campaign}
						onSave={handleSubmit}
						onSaveNext={() => handleSubmit(true)}
						disabled={isSubmitting}
					/>
				</Fragment>
			</div>
		</Fragment>
	);
};

export default CampaignKeywordsScreen;
